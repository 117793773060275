import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { muiTheme, theme } from 'constants/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import LogRocket from 'logrocket';
import { ReactQueryDevtools } from 'react-query/devtools';
import Head from 'next/head';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import GlobalStyles from 'components/GlobalStyles/GlobalStyles';
import { setConfig } from 'cloudinary-build-url';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, CssFontSource } from '@stripe/stripe-js';
import * as Fathom from 'fathom-client';

setConfig({
  cloudName: 'solin-fitness',
});

import 'components/GlobalStyles/global.css';
import 'components/GlobalStyles/fonts.css';

import 'components/SlidingPanel/SlidingPanel.css';
import 'react-multi-carousel/lib/styles.css';
import 'nprogress/nprogress.css';
import { LOGIN } from 'constants/routes';
import { useUpdateCookies } from 'hooks/useUpdateCookies';
import AccountPastDueAlert from 'components/AcccountPastDueAlert/AcccountPastDueAlert';

const IS_DEV = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';
const HEAP_ID = process.env.NEXT_PUBLIC_HEAP_ID;

const stripeKey = process.env.NEXT_PUBLIC_STRIPE_PK;
const stripePromise = loadStripe(stripeKey || '');
const fontStyle: CssFontSource = {
  cssSrc:
    'https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500&display=swap',
};

// TODO once we have domain change this
if (!IS_DEV) {
  ReactGA.initialize('G-9H69RM2RTN');
  LogRocket.init('solinfitness/solintv-consumer');
}

interface Heap {
  track: (event: string, properties?: Record<string, unknown>) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Record<string, unknown>) => void;
  addEventProperties: (properties: Record<string, unknown>) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}
declare global {
  interface Window {
    heap: Heap;
  }
}

const themeConst = createMuiTheme(muiTheme);

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

type Props = AppProps & {
  accessToken?: string;
};

const SolinApp = ({ Component, pageProps }: Props) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // ✅ globally set retries to 2 attemps
            retry: 2,
            // ✅ globally default to 20 seconds
            staleTime: 1000 * 20,
          },
        },
      }),
  );

  const router = useRouter();

  useEffect(() => {
    const userSession = localStorage.getItem('authtoken');
    if (userSession) {
      localStorage.clear();
      queryClient.removeQueries(['profile', 'metadata']);
      router.push(LOGIN);
    }
  }, []);

  useEffect(() => {
    Fathom.load('KNOYTOLG', {
      includedDomains: ['solin.stream', 'www.solin.stream'],
    });

    const onRouteChangeComplete = () => Fathom.trackPageview();

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () =>
      router.events.off('routeChangeComplete', onRouteChangeComplete);
  }, []);

  const FacebookPixel = () => {
    useEffect(() => {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init('330793878378588');
          ReactPixel.pageView();
        });
    });
    return null;
  };

  useUpdateCookies();

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={themeConst}>
        <QueryClientProvider client={queryClient}>
          <Elements stripe={stripePromise} options={{ fonts: [fontStyle] }}>
            <Head>
              <title>Solin - The Home of Social Fitness</title>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
              <meta
                property="og:title"
                content="Solin - The Home of Social Fitness"
                key="title"
              />
              <meta
                property="og:description"
                content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
              />
              <meta
                property="og:image"
                content="https://www.solin.stream/images/ogImageLogo.png"
                key="image"
              />
              <meta
                name="description"
                key="description"
                content="Solin is the home for private communities and fitness programs with your favorite creators. Talk, chat, hang out, share photos, stay close with your friends."
              />
              <link rel="icon" href="/images/favicon.ico" />
              <script
                src="https://apis.google.com/js/api.js"
                type="text/javascript"
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                 heap.load(${HEAP_ID});`,
                }}
              />
            </Head>
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} />
              <AccountPastDueAlert />
            </Hydrate>
            <FacebookPixel />
            <GlobalStyles />
            <ReactQueryDevtools initialIsOpen={false} />
          </Elements>
        </QueryClientProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(SolinApp);
