import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';

interface AuthState {
  isSignedIn: boolean;
  userId: number | undefined;
  userType: number | undefined;
  logUserIn: (userId: number, userType: number) => void;
  logUserOut: () => void;
}

const useAuthStore = create<
  AuthState,
  SetState<AuthState>,
  GetState<AuthState>,
  StoreApiWithPersist<AuthState>
>(
  persist(
    (set, get) => ({
      isSignedIn: get()?.isSignedIn,
      userId: get()?.userId,
      userType: get()?.userType,
      logUserIn: (userId: number, userType: number) =>
        set(() => ({
          isSignedIn: true,
          userId,
          userType,
        })),
      logUserOut: () => set(() => ({ isSignedIn: false, userId: undefined })),
    }),
    {
      name: 'auth-storage',
    },
  ),
);

export default useAuthStore;
