import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useAuthStore from './useAuthStore';
import { LOGIN } from '../constants/routes';
import { logoutUser } from 'queries/auth';

/**
 * Store in local storage if they have logged out to get the new cookies */
const key = 'hasUpdatedCookies';

const useUpdateCookies = () => {
  const router = useRouter();
  const { isSignedIn, logUserOut } = useAuthStore();

  const onLogout = () => {
    logoutUser().then(() => {
      logUserOut();
      router.push(LOGIN);
    });
  };

  useEffect(() => {
    const hasUpdatedCookies = localStorage.getItem(key);
    if (!hasUpdatedCookies && isSignedIn) {
      localStorage.setItem(key, 'true');
      onLogout();
    }
  }, []);

  return;
};

export { useUpdateCookies };
